<template>
  <div>
    <DashboardHero
      :title="`Férias ${year}`"
      tag="Gestão"
      type="cover"
      size="xs"
      background="abstract-01"
      filters-level="light"
    />

    <fw-panel title="Números" class="my-5">
      <PanelStats :stats="stats" :stats-warning="false" :loading="loading.stats" />
    </fw-panel>

    <fw-panel
      v-if="userPermissions.isMapsManager"
      title="Visão anual"
      subtitle="Alocação de férias dos trabalhadores."
      class="my-5"
    >
      <ContentLoader v-if="loading.stats" />
      <BlockYearActivity v-else :data="yearActivity" :year="year" :expandable="true" />
    </fw-panel>

    <fw-panel title="Recentes" class="my-5" :loading="loading.stats">
      <template #default>
        <PanelManageInboxResume v-if="year" :year="year" />
      </template>
    </fw-panel>

    <fw-panel-info label="Data (raw)" debug class="my-5">
      <json-viewer :value="{ userPermissions, stats, yearActivity }"></json-viewer>
    </fw-panel-info>
  </div>
</template>

<script>
import DashboardHero from '@/fw-modules/fw-core-vue/ui/components/dashboard/DashboardHero'
import PanelStats from '@/fw-modules/fw-core-vue/ui/components/panels/PanelStats'
import PanelManageInboxResume from '@/components/panels/manage/PanelManageInboxResume'
import BlockYearActivity from '@/fw-modules/fw-core-vue/ui/components/blocks/BlockYearActivity'
import utils from '@/fw-modules/fw-core-vue/utilities/utils'
import Dates from '@/fw-modules/fw-core-vue/utilities/dates'
import ContentLoader from '@/fw-modules/fw-core-vue/ui/components/animation/ContentLoader'

export default {
  components: {
    DashboardHero,
    PanelStats,
    BlockYearActivity,
    ContentLoader,
    PanelManageInboxResume
  },

  props: {
    year: {
      type: Number,
      required: true
    }
  },

  data() {
    return {
      stats: [],
      count: {},
      pendingActions: {},
      loading: {
        stats: true,
        pendingActions: true
      }
    }
  },

  computed: {
    api() {
      return this.$store.state.api.base
    },

    user() {
      return this.$store.getters.getUser
    },

    language() {
      return this.$store.state.language
    },

    userPermissions() {
      return this.$store.getters.userPermissions
    },

    yearActivity() {
      let days = {}
      if (!this.count?.mine?.vacation_days_count) return days
      for (const [day, count] of Object.entries(this.count?.mine?.vacation_days_count)) {
        days[Dates.format(day, 'DD/MM/YYYY')] = count
      }

      return days
    }
  },

  mounted() {
    this.loadStats()
  },

  methods: {
    async loadStats() {
      await utils.tryAndCatch(this, async () => {
        const res = await this.api.getManagerMapsYearStats(this.year)
        console.log('getManagerMapsYearStats', res.count)
        this.count = res.count

        // Parse global stats
        if (this.userPermissions.isManager || this.userPermissions.isAdmin) {
          if (this.userPermissions.isMapsManager) {
            this.stats.push(
              {
                label: 'Equipas',
                value: this.count?.mine?.maps || 0,
                description: null
              },
              {
                label: 'Trabalhadores',
                value: this.count?.mine?.maps_users || 0,
                description: null
              }
            )
          } else {
            this.stats.push(
              {
                label: 'Equipas',
                value: this.count?.global?.maps || 0,
                description: null
              },
              {
                label: 'Trabalhadores',
                value: this.count?.global?.maps_users || 0,
                description: null
              }
            )
          }
        }

        this.stats.push(
          {
            label: 'Aprovados',
            value: this.count.mine?.maps_users_status?.approved || 0,
            description: null
          },
          {
            label: 'Parecer emitido',
            value: this.count.mine?.maps_users_status?.pre_approved || 0,
            description: null
          },
          {
            label: 'Submetidos',
            value: this.count.mine?.maps_users_status?.submitted || 0,
            description: null
          },
          {
            label: 'Em rascunho',
            value: this.count.mine?.maps_users_status?.draft || 0,
            description: null
          }
        )
      })

      this.loading.stats = false
    }
  }
}
</script>
